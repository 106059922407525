<template>

	<div class="aside_root">
		<!--logo-->
		<router-link to="/" class="logo_link">
			<div class="logo">
				<!--<img :src="logo" alt="">-->
				<span class="logo_text">PG_Code助手</span>
			</div>
		</router-link>

		<!--内容区-->
		<div class="aside_content">
			<el-menu
					:collapse-transition="false" :default-active="$route.path" background-color="rgb(48,65,86)"
					active-text-color="rgb(64,158,255)" text-color="#fff" router
			>
				<div v-for="(item,i) in lastMenu" :key="i">
					<el-submenu :index="item.pRouter" v-if="item.children">
						<template slot="title">
							<i :class="item.icon"></i>
							<span>{{ item.name }}</span>
						</template>
						<el-menu-item :index="child.cRouter" class="sub_item" v-for="(child,i) in item.children" :key="i">
							<!--<i :class="child.icon"></i>-->
							<i class="el-icon-set-up"></i>
							<span slot="title">{{ child.name }}</span>
						</el-menu-item>
					</el-submenu>
					<el-menu-item :index="item.pRouter" v-else>
						<i :class="item.icon"></i>
						<span slot="title">{{ item.name }}</span>
					</el-menu-item>
				</div>
			</el-menu>
		</div>
	</div>
</template>

<script>

export default {
	name: "sidebar",
	data() {
		return {
			menuList: [
				{id: '1', pid: '', name: '系统首页', router: '/home', icon: 'el-icon-s-home'},
				{id: '2', pid: '', name: '信息管理', router: '/info', icon: 'el-icon-s-custom'}
			],
			lastMenu: []
		}
	},
	mounted() {
		this.getMenu();
	},
	methods: {
		getMenu() {
			let pMenu = [] //父级菜单
			let cMenu = [] //子菜单
			if (this.menuList.length > 0) {
				//有数据则进行下一步
				//遍历菜单列表，如果没有pid(父级id，没有表示他是一级菜单)，则放进pMeun,有则放进cMeun
				this.menuList.forEach((item) => {
					if (!item.pid) {
						pMenu.push({
							id: item.id,
							name: item.name,
							icon: item.icon,
							pRouter: item.router,
							// meta: { title: i.name, icon: i.icon, access: 1 },
							children: []
						})
					} else {
						cMenu.push(item)
					}
				})
				console.log(pMenu, cMenu)
				//判断pMenu和cMenu中id和pid是否相等，相等则将该项加入对应pMeun的children中
				pMenu.forEach((pItem) => {
					cMenu.forEach((cItem) => {
						if (cItem.pid == pItem.id) {
							// console.log(pitem, '11')
							pItem.children.push({
								id: cItem.id,
								name: cItem.name,
								cRouter: cItem.router
								//  meta: { title: i.name, icon: i.icon, access: 1 },
							})
						}
					})
				})
				//如果pMenu中的children没有数据的，则删除该children属性
				pMenu.forEach((item, index) => {
					if (!item.children.length) {
						delete pMenu[index].children
					}
				})

				// console.log(pMenu, 'pMeun')
				this.lastMenu = pMenu //赋值给菜单数据源
			} else {
				return;
			}
		},
		handleMenuSelect(index) {
			const targetPath = '/' + index;

			// 判断目标路径是否与当前路径相同
			// 通过 this.$route.path 获取到当前路由的路径
			if (this.$route.path === targetPath) {
				// 如果相同则不进行导航
				return;
			}

			// 否则进行导航
			this.$router.push({path: targetPath});
		}
	}
}
</script>

<style scoped>

.el-menu-item {
	border-radius: 20px !important;
}

.sub_item {
	padding-left: 40px !important;
}

.logo {
	text-align: center;
	border-bottom: 1px solid #dedede;
	padding: 20px;
	margin-bottom: 10px;
	font-weight: bold;
	cursor: pointer;
	color: #fff;
}

.logo img {
	width: 50px;
	height: 50px;
	font-size: 24px;
	margin-right: 5px;
}

.logo_link {
	text-decoration: none;
}


</style>
