<template>
	<el-container>
		<!--侧边栏-->
		<el-aside>
			<Sidebar/>
		</el-aside>
		<el-container>
			<!--头部-->
			<el-header>
				<Header/>
			</el-header>
			<!--内容-->
			<el-main>
				<router-view/>
			</el-main>
		</el-container>
	</el-container>
</template>

<script>
import Header from "@/layout/components/Header";
import Sidebar from "@/layout/components/sidebar";

export default {
	name: "index",
	components: {
		Header,
		Sidebar
	}
}
</script>

<style scoped>

</style>
