import Vue from 'vue'
import VueRouter from 'vue-router'
import Layout from "@/layout";

Vue.use(VueRouter)

const routes = [
    {
        path: '/',//根目录路由为/
        component: Layout,//指定使用Layout组件布局
        redirect: '/home',//重定向至/home页面
        children: [{//子菜单信息
            path: '/home',//路径
            name: 'home',
            component: () => import('../views/HomeView.vue'),//指定组件
            meta: {title: '首页', closeFlag: true}
        }]
    },
    {
        path: '/info',//根目录路由为/
        component: Layout,//指定使用Layout组件布局
        children: [{//子菜单信息
            path: '/info',//路径
            name: 'info',
            component: () => import('../views/InformationView/index.vue'),//指定组件
            meta: {title: '信息管理'}
        }]
    },
    {
        path: '/score',//根目录路由为/
        component: Layout,//指定使用Layout组件布局
        children: [{//子菜单信息
            path: '/score',//路径
            name: 'score',
            component: () => import('../views/ScoreManageView/index.vue'),//指定组件
            meta: {title: '成绩管理'}
        }]
    },
    {
        path: '/login',
        name: 'login',
        component: () => import('../views/LoginView.vue'),
        meta: {title: '登录'}
    },
    {
        path: '/register',
        name: 'register',
        component: () => import('../views/RegisterView.vue'),
        meta: {title: '注册'}
    },
    {
        path: '/test',
        name: 'test',
        component: () => import('../views/test.vue'),
        meta: {title: '注册'}
    },
]

// 防止连续点击多次路由报错
let routerPush = VueRouter.prototype.push;
let routerReplace = VueRouter.prototype.replace;
// push
VueRouter.prototype.push = function push(location) {
    return routerPush.call(this, location).catch(err => err)
}
// replace
VueRouter.prototype.replace = function push(location) {
    return routerReplace.call(this, location).catch(err => err)
}

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

 //全局前置路由守卫————初始化的时候被调用、每次路由切换之前被调用
router.beforeEach((to, from, next) => {
    let user = localStorage.getItem('user');
    if(!user && (from.name !== 'login' || to.name !== 'register')){
        if (to.name !== 'login' && !user) next({ name: 'login' })
        else next()
    }else {
        next()
    }
})

export default router
